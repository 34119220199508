/**
 * Created by mac on 12/14/20
 */
var UnitView = cc.Node.extend({
    ctor: function (unit) {
        this._super();

        var data = unit.getData();

        this.unit = unit;
        this.layer = data.lovesGround ? Map2d.ABOVE_GROUND : Map2d.LAYER_UNITS;

        var composite = this.unit.findComponent(Composite);
        var parts = composite && composite.getParts();
        if (parts && parts.length) {
            this.parts = parts;
            this.partsViews = [];
        }

        this.additionalViews = {};

        var sprite = UnitView.getUnitImage(unit, {
            keepLazy: true
        });

        this.setUnitImage(sprite);

        unit.setView(this);

        if (cleverapps.config.demoMode && cleverapps.unitsDemoMode.selectedUnit() === unit.code) {
            unit.highlight();
        }

        this.unit.components.forEach(function (component) {
            ViewFactory(component, this);
        }, this);
        this.restoreState();

        if (Map2d.currentMap.isFlat && this.shouldMarkAsLast()) {
            this.markLast();
        }
    },

    shouldMarkAsLast: function () {
        if (this.unit.findComponent(Mineable) || this.unit.findComponent(Customer) || this.unit.findComponent(ThirdElement)) {
            return false;
        }

        return this.unit.isLast();
    },

    hasBoneAnchor: function () {
        return this.sprite.getAnchorBone && this.sprite.getAnchorBone();
    },

    setUnitImage: function (sprite) {
        if (this.sprite) {
            this.sprite.removeFromParent();
        }
        this.sprite = sprite;

        sprite.saveStack = true;

        var spriteSize = sprite.getContentSize();
        var unitSize = {
            width: Math.max(cleverapps.styles.UnitView.minimalSize.width, spriteSize.width),
            height: Math.max(cleverapps.styles.UnitView.minimalSize.height, spriteSize.height)
        };

        var useAnchorBone = this.hasBoneAnchor();
        if (useAnchorBone) {
            var spriteAnchor = sprite.getAnchorPoint();
            var adjustedAnchorX = (spriteAnchor.x * spriteSize.width) / unitSize.width;
            var adjustedAnchorY = (spriteAnchor.y * spriteSize.height) / unitSize.height;
            this.setAnchorPoint(adjustedAnchorX, adjustedAnchorY);
            sprite.setAnchorPoint(0.5, 0);
        } else {
            var styles = UnitView.getUnitAnchor(this.unit);
            this.setAnchorPoint(0.5, styles.y);
            sprite.setAnchorPoint(styles.x, 0);
        }

        this.setContentSize2(unitSize);
        sprite.setPositionRound(this.width / 2, (unitSize.height - this.sprite.height) / 2);

        this.removeParts();
        this.createParts();
        this.addChild(sprite);
    },

    createParts: function () {
        if (!this.parts) {
            return [];
        }

        if (!this.partsViews.length) {
            var styles = UnitView.getUnitAnchor(this.unit);

            this.partsViews = this.parts.map(function (part) {
                var spine = new cleverapps.Spine(UnitView.UnitIconJson(this.unit));
                spine.setAnimation(0, part.animation, true);
                spine.setAnchorPoint(styles.x, styles.y);
                spine.part = part;
                return spine;
            }.bind(this));
        }

        return this.partsViews;
    },

    removeParts: function () {
        if (this.partsViews && this.partsViews.length) {
            this.partsViews.forEach(function (part) {
                part.removeFromParent(this);
            });
            this.partsViews = [];
        }
    },

    restoreState: function () {
        this.createPrizeView();
        this.createPoints();
        this.updateCustomerMark();
    },

    cleanup: function () {
        this._super();

        this.unit.removeView();

        this.removeParts();
    },

    removeFromParent: function (cleanup) {
        if (this.startDragDelta && this._parent && (cleanup === undefined || cleanup)) {
            cleverapps.throwAsync("Remove dragging unit view");
        }

        this._super(cleanup);
    },

    clearAnimations: function () {
        this.unit.clearAnimations();

        ["squeezeAction", "mergeAction", "mergeAnimateAction", "adjustPositionAction", "addAnimationAction"].forEach(function (actionName) {
            var action = this[actionName];
            if (action && !action.isDone()) {
                this.stopAction(action);
            }
            delete this[actionName];
        }, this);
    },

    replaceParent: function (newParent) {
        this.removeParts();

        this._super(newParent);
    },

    setVisible: function (visible) {
        cc.Node.prototype.setVisible.call(this, visible);

        if (this.partsViews && this.partsViews.length) {
            this.partsViews.forEach(function (partView) {
                partView.setVisible(visible);
            });
        }
    }
});

UnitView.prototype.checkTouchInside = function (touch, isTallUnit) {
    if (!this.isDisplayed()) {
        return false;
    }

    var point = this.convertTouchToNodeSpace(touch);

    return cleverapps.UI.targetContainPoint(this, point, isTallUnit);
};

UnitView.prototype.showAdditionalViews = function () {
    var mapView = Map2d.currentMap.getMapView();

    for (var type in this.additionalViews) {
        var view = this.additionalViews[type];
        view.replaceParent(this);
        view.setPositionRound(view.basePosition);
        mapView.upAdditionalViewAboveClouds(this, view);

        this.additionalViews[type].animateAppear();
    }
};

UnitView.prototype.hideAdditionalViews = function () {
    for (var type in this.additionalViews) {
        this.additionalViews[type].animateHide();
    }
};

UnitView.prototype.removeAdditionalViews = function () {
    Object.keys(this.additionalViews).forEach(function (type) {
        this.removeAdditionalView(type, true);
    }.bind(this));

    InfoView.Clear(this.unit);
};

UnitView.prototype.onRemove = function () {
    if (typeof Merge !== "undefined" && !Merge.currentMerge.stopped && Map2d.currentMap.workers.findAssigned(this.unit)) {
        cleverapps.throwAsync("Remove worker assigned unit");
    }

    this.removeFromParent();
};

UnitView.prototype.onTradeSell = function (callback) {
    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.5),
        new cc.CallFunc(function () {
            var shine = new cleverapps.Spine(bundles.merge.jsons.trade_exchange_json);
            shine.setLocalZOrder(1);
            shine.setAnimation(0, "use", false);
            shine.setCompleteListenerRemove(callback);
            shine.setSafeToRemove();

            var map2dView = Map2d.currentMap.getMapView();
            map2dView.alignViewInGrid(this.unit.x, this.unit.y, shine);
            map2dView.animations.addChild(shine);
        }.bind(this)),
        new cc.ScaleTo(0.5, 0.2).easing(cc.easeBackIn()),
        new cc.RemoveSelf()
    ));
};

UnitView.prototype.onTradeBuy = function (callback) {
    this.unit.hideAdditionalViews();
    this.setScale(0);
    this.runAction(new cc.Sequence(
        new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()),
        new cc.CallFunc(function () {
            this.unit.showAdditionalViews();
        }.bind(this)),
        new cc.DelayTime(1),
        new cc.CallFunc(callback)
    ));
};

UnitView.prototype.getSpawnStart = function (parentUnit, options) {
    var map2dView = Map2d.currentMap.getMapView();

    var start;
    if (options.fromNode) {
        var cellPos = map2dView.alignInGrid(this.unit.x, this.unit.y);
        start = parentUnit.parent ? this.getRelativeCoordinates(parentUnit) : cc.p(parentUnit);
        start.x -= cellPos.x;
        start.y -= cellPos.y;
    } else {
        start = map2dView.toScreen(parentUnit.x - this.unit.x, parentUnit.y - this.unit.y);
        start.y += Map2d.currentMap.isFlat ? 0 : cleverapps.styles.Map2dView.cell.height / 2;
        var multiCell = parentUnit.findComponent && parentUnit.findComponent(MultiCell);
        if (multiCell) {
            var centerDelta = multiCell.getCenter();
            start.x += centerDelta.x;
            start.y += centerDelta.y;
        }
    }

    var defaultStartScale = cleverapps.config.name === "garden" ? 0.7 : 0.3;
    var startScale = options.startScale || defaultStartScale;

    this.setScaleX(typeof startScale === "object" ? startScale.x : startScale);
    this.setScaleY(typeof startScale === "object" ? startScale.y : startScale);

    if (options.startAnchors) {
        start.x += this.scaleX * this.width * (this.anchorX - options.startAnchors.anchorX);
        start.y += this.scaleY * this.height * (this.anchorY - options.startAnchors.anchorY);
    }

    return map2dView.alignPositionInGrid(this.unit.x, this.unit.y, start);
};

UnitView.prototype.spawn = function (parentUnit, id, options) {
    this.stopAllActions();
    this.unit.hideAdditionalViews();

    var realParent = this.getParent();
    var movingNode = cleverapps.scenes.getMovingNode(this);
    this.replaceParentSamePlace(movingNode, {
        keepScale: true
    });

    if (Unit.IsApplicable({ code: "dwarf" }, parentUnit) && Merge.currentMerge.tutorial.checkTargets(parentUnit)) {
        this.setVisible(false);
        this.runAction(new cc.Sequence(
            StandartAnimations.showUp(this, { flash: true }),
            new cc.ReplaceParent(realParent)
        ));
    } else {
        var start = this.getSpawnStart(parentUnit, options);
        var dist = { x: this.x - start.x, y: this.y - start.y };
        this.setPositionRound(start.x, start.y);

        var path;
        if (cleverapps.styles.UnitView.enableSpawnPath) {
            path = new cleverapps.Particles(bundles.particle.jsons.particles_spawn_json, bundles.particle.frames.particle_texture);
            movingNode.addChild(path);
            path.followTarget(this);
        }

        var time = (Map2d.currentMap.isFlat ? 0.3 : 0.6) * (0.5 + 0.001 * cc.pLength(dist));

        var appearAnimation, finishAnimation;

        if (Map2d.currentMap.isFlat) {
            var normalizedReverseDir = cc.pNormalize(cc.p(-dist.x, -dist.y));
            var offset = cc.pMult(normalizedReverseDir, 10);

            var endPosX = this.x + dist.x;
            var endPosY = this.y + dist.y;

            var spawnHeight = !options.fromNode ? 0 : cleverapps.styles.UnitView.spawnHeight;

            appearAnimation = new cc.Spawn(
                new cc.Sequence(
                    new cc.ScaleTo(time * 0.5, 1.3).easing(cc.easeIn(1)),
                    new cc.ScaleTo(time * 0.5, 0.8).easing(cc.easeOut(1))
                ),
                new cc.JumpAnimation(time, this, { x: dist.x + offset.x, y: dist.y + offset.y }, spawnHeight)
            );

            finishAnimation = new cc.Spawn(
                new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut(2)),
                new cc.MoveTo(0.3, endPosX, endPosY).easing(cc.easeOut(1))
            );
        } else {
            appearAnimation = new cc.Spawn(
                new cc.ScaleTo(time, 1).easing(cc.easeOut(2)),
                new cc.JumpAnimation(time, this, dist, cleverapps.styles.UnitView.spawnHeight)
            );

            finishAnimation = ["heroes", "wooden"].indexOf(cleverapps.config.ui) !== -1 ? StandartAnimations.ballEffect(this) : StandartAnimations.squeeze(this);
        }

        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(id * 0.1),
            new cc.Show(),
            appearAnimation,
            new cc.PlaySound(bundles.merge.urls.spawn_landing_effect),
            finishAnimation,
            new cc.ReplaceParent(realParent),
            new cc.CallFunc(this.unit.showAdditionalViews.bind(this.unit))
        )).setFinalize(function () {
            if (path) {
                path.stop();
            }
        });
    }
};

UnitView.prototype.showUp = function (callback) {
    if (!(this.sprite instanceof cleverapps.Spine && this.sprite.hasAnimation("showup"))) {
        callback();
        return;
    }

    var savedParent = this.getParent();
    this.replaceParentSamePlace(Map2d.currentMap.getMapView().animations, {
        keepScale: true
    });

    Map2d.currentMap.focusOnUnit(this.unit, {
        allowScrollWithFocus: true
    });
    cleverapps.audio.playSound(bundles.merge.urls["showup_effect_" + Unit.GetKey(this.unit)]);
    this.sprite.setAnimation(0, "showup", false);
    this.sprite.setCompleteListenerOnce(function () {
        this.replaceParentSamePlace(savedParent, {
            keepScale: true
        });

        this.sprite.setAnimation(0, "idle", true);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(callback)
        ));
    }.bind(this));
};

UnitView.prototype.outline = function (restore) {
    if (cleverapps.skins.getSlot("skinName") !== "adventure") {
        return;
    }

    if (restore && this.sprite.outlineSprite && (!InfoView.IsDisplayedFor(this.unit) || this.getAdditionalView("ingredients"))) {
        this.sprite.outlineSprite.removeFromParent();
        delete this.sprite.outlineSprite;
    }

    if (!restore && !this.sprite.outlineSprite) {
        this.runAction(StandartAnimations.outline(this.sprite));
    }
};

UnitView.prototype.squeeze = function () {
    if (this.squeezeAction && !this.squeezeAction.isDone()) {
        this.stopAction(this.squeezeAction);
    }

    this.squeezeAction = this.runAction(StandartAnimations.squeeze(this));
};

UnitView.prototype.isClickable = function () {
    if (!Map2d.currentMap.getMapView().containsView(this)) {
        return;
    }

    if (this.unit.getData().clickable === false) {
        return;
    }

    if (!this.sprite.isVisible()) {
        return;
    }

    if (this.unit.isLockedByTutorial()) {
        return;
    }

    return true;
};

UnitView.prototype.handleClick = function () {
    if (!this.isClickable()) {
        return false;
    }

    if (cleverapps.config.demoMode) {
        InfoView.SqueezeAndDisplay(this.unit);
        return true;
    }

    if (!InfoView.IsDisplayedFor(this.unit) && this.unit.handleClick()) {
        InfoView.CloseInfo();
        return true;
    }

    cleverapps.audio.playSound(bundles.main.urls.click_effect);

    InfoView.SqueezeAndDisplay(this.unit);

    return true;
};

UnitView.prototype.startDidMerged = function () {
    var styles = cleverapps.styles.UnitView;

    var mapView = Map2d.currentMap.getMapView();
    var json = cleverapps.skins.getSlot("merge_animation_json") || bundles.merge_animations.jsons.merge_animation_json;

    var animationAbove = new cleverapps.Spine(json);
    animationAbove.setPositionRound(styles.mergeAnimation);
    animationAbove.setCompleteListenerRemove();
    animationAbove.setSafeToRemove();
    mapView.alignViewInGrid(this.unit.x, this.unit.y, animationAbove);
    mapView.animations.addChild(animationAbove);

    animationAbove.runAction(new cc.Sequence(
        new cc.DelayTime(styles.mergeAnimation.delay),
        new cc.CallFunc(function () {
            animationAbove.setAnimation(0, "animation", false);
        })
    ));

    if (!this.unit.isMultiCell() && cleverapps.Spine.hasAnimation("animation_below", json)) {
        var animationBelow = new cleverapps.Spine(json);
        animationBelow.setPositionRound(styles.mergeAnimationBelow);
        animationBelow.setCompleteListenerRemove();
        animationBelow.setSafeToRemove();
        animationBelow.setLocalZOrder(-1);
        mapView.addTile(Map2d.LAYER_UNITS, this.unit.x, this.unit.y, animationBelow);

        animationBelow.runAction(new cc.Sequence(
            new cc.DelayTime(styles.mergeAnimationBelow.delay),
            new cc.CallFunc(function () {
                animationBelow.setAnimation(0, "animation_below", false);
            })
        ));
    }
};

UnitView.prototype.showFreshAnimation = function () {
    var animations = Map2d.currentMap.getMapView().animations;

    var unitView = UnitView.getUnitImage(this.unit, { preferStatic: true });
    unitView.setPositionRound(animations.convertToNodeSpace(this.parent.convertToWorldSpace(this.getPosition())));
    unitView.setVisible(false);
    animations.addChild(unitView);

    unitView.runAction(new cc.Sequence(
        new cc.DelayTime(0.7),
        new cc.Show(),
        StandartAnimations.animateCollect(unitView, "unitsLibrary", {
            path: true,
            adjustToTarget: true,
            collectEffect: true,
            sound: bundles.menubar.urls.exp_fly_finish_effect
        }),
        new cc.RemoveSelf()
    ));
};

UnitView.prototype.didMerged = function (startPoint, index) {
    var styles = cleverapps.styles.UnitView;

    this.stopAllActions();
    this.removePath();
    this.setVisible(false);
    this.unit.hideAdditionalViews();

    var delay = (cleverapps.gameModes.axemerge ? 0.3 : styles.didMerge.delay) + 0.1 * index;

    var time, moveAnimation, scaleAnimation;

    if (index === 0 && !this.unit.isMultiCell()) {
        time = 0.48;
        moveAnimation = new cc.DelayTime(0);
        scaleAnimation = new cc.Sequence(
            new cc.CallFunc(function () {
                this.setScale(0.1);
            }.bind(this)),
            new cc.ScaleTo(0.2, 1.2).easing(cc.easeOut(2)),
            new cc.ScaleTo(0.2, 1).easing(cc.easeIn(2)),
            new cc.CallFunc(function () {
                this.unit.showAdditionalViews();
            }.bind(this))
        );
    } else {
        var map2dView = Map2d.currentMap.getMapView();
        var start = map2dView.toScreen(startPoint.x - this.unit.x, startPoint.y - this.unit.y);
        start = map2dView.alignPositionInGrid(this.unit.x, this.unit.y, start);

        var dist = { x: this.x - start.x, y: this.y - start.y };

        time = 0.5 * (0.5 + 0.002 * Math.sqrt(dist.x * dist.x + dist.y * dist.y));
        if (time > 0.4) {
            time = 0.4;
        }

        moveAnimation = new cc.Sequence(
            new cc.CallFunc(function () {
                this.setPosition(start.x, start.y);
            }.bind(this)),
            new cc.JumpAnimation(time, start, dist, styles.mergeSpawnHeight)
        );

        scaleAnimation = new cc.Sequence(
            new cc.CallFunc(function () {
                this.setScale(0.5);
            }.bind(this)),
            new cc.ScaleTo(time, 1).easing(cc.easeOut(2)),
            new cc.CallFunc(function () {
                this.squeeze();
                this.unit.showAdditionalViews();
            }.bind(this))
        );
    }

    if (cleverapps.config.name === "fairy") {
        scaleAnimation = new cc.Sequence(
            new cc.CallFunc(function () {
                this.setScale(0.2);
            }.bind(this)),
            new cc.ScaleTo(time * 0.7, 1.09).easing(cc.easeIn(1.6)),
            new cc.ScaleTo(time * 0.3, 0.95),
            new cc.CallFunc(function () {
                this.unit.showAdditionalViews();
            }.bind(this)),
            new cc.ScaleTo(0.23, 1.02).easing(cc.easeOut(1.6)),
            new cc.ScaleTo(0.4, 1)
        );
    }

    var parent = this.getParent();
    this.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this));

    this.mergeAction = this.runAction(new cc.Sequence(
        new cc.DelayTime(delay),
        new cc.Spawn(
            new cc.Show(),
            moveAnimation,
            scaleAnimation
        )
    ));
    this.mergeAction.setFinalize(function () {
        this.replaceParentSamePlace(parent);
    }.bind(this));
};

UnitView.prototype.stopAnimateMerge = function (target) {
    if (this.mergeAnimateAction && !this.mergeAnimateAction.isDone()) {
        this.stopAction(this.mergeAnimateAction);
        delete this.mergeAnimateAction;
    }
    if (this.unit !== target && (!this.mergeAction || this.mergeAction.isDone())) {
        this.mergeAnimateAction = this.runAction(new cc.Spawn(
            new cc.MoveTo(0.1, Map2d.currentMap.getMapView().alignInGrid(this.unit.x, this.unit.y)),
            new cc.ScaleTo(0.1, 1)
        ));
    } else {
        this.mergeAnimateAction = this.runAction(new cc.ScaleTo(0.1, 1));
    }
};

UnitView.prototype.merge = function (target) {
    var mapView = Map2d.currentMap.getMapView();
    this.replaceParentSamePlace(mapView.animations);
    var targetPos = mapView.alignInGrid(target.x, target.y);

    if (cleverapps.config.name === "fairy") {
        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.3, targetPos).easing(cc.easeIn(2)),
            new cc.RemoveSelf()
        ));
    } else {
        this.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.MoveTo(0.3, targetPos).easing(cc.easeIn(2)),
                new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn())
            ),
            new cc.RemoveSelf()
        ));
    }
};

UnitView.prototype.startAnimateMerge = function (target) {
    if (this.mergeAnimateAction && !this.mergeAnimateAction.isDone()) {
        this.stopAction(this.mergeAnimateAction);
        delete this.mergeAnimateAction;
    }

    var actions = [];
    actions.push(new cc.Sequence(
        new cc.ScaleTo(0.325, 1.03),
        new cc.ScaleTo(0.325, 1),
        new cc.DelayTime(0.1)
    ));
    var startingPosition = this.getPosition();
    var mapView = Map2d.currentMap.getMapView();
    if (this.unit.distanceTo(target) !== 0 && this.unit !== target) {
        var targetPosition = mapView.alignInGrid(target.x, target.y);
        var position = mapView.alignInGrid(this.unit.x, this.unit.y);
        var dx = targetPosition.x - position.x;
        var dy = targetPosition.y - position.y;

        var length = Math.sqrt(dx * dx + dy * dy);
        dx = dx / length * cleverapps.styles.UnitView.merge;
        dy = dy / length * cleverapps.styles.UnitView.merge;

        if (this.unit.distanceTo(target) > 2) {
            dx /= 2;
            dy /= 2;
        }

        if (cleverapps.gameModes.automerge && Merge.currentMerge.advice && !Merge.currentMerge.advice.hintActive) {
            actions.push(new cc.Sequence(
                new cc.MoveBy(0.33, -dx, -dy).easing(cc.easeBackOut()),
                new cc.DelayTime(0.2),
                new cc.MoveBy(0.22, dx, dy).easing(cc.easeBackIn())
            ));
        } else {
            actions.push(new cc.Sequence(
                new cc.CallFunc(function () {
                    cleverapps.audio.playSound(bundles.merge.urls.merge_hint);
                }),
                new cc.MoveBy(0.450, dx, dy).easing(cc.easeBackIn()),
                new cc.MoveBy(0.2, -dx, -dy).easing(cc.easeOut(2)),
                new cc.DelayTime(0.1)
            ));
        }
    }

    if (actions.length > 0) {
        var action = actions.length === 1 ? actions[0] : new cc.Spawn(actions);
        this.mergeAnimateAction = this.runAction(new cc.RepeatForever(action)).setFinalize(function () {
            this.setPosition(startingPosition);
        }.bind(this));
    }
};

UnitView.prototype.handleDragStart = function (touch) {
    if (!this.unit.handleDragStart()) {
        return false;
    }

    this.stopAllActions();

    InfoView.CloseInfo();

    this.unit.hideAdditionalViews();

    var mapView = Map2d.currentMap.getMapView();
    this.replaceParentSamePlace(mapView.animations);

    var position = this.parent.convertToNodeSpace(touch.getStartLocation());
    this.startDragDelta = cc.p(this.x - position.x, this.y - position.y);

    this.debugDeltaCreate = {
        when: new Date(),
        stack: new Error().stack
    };

    if ((this.unit.findComponent(Shine) || this.unit.findComponent(Chest) || this.unit.findComponent(Fruit)) && connector.info.source !== "playable") {
        var path = this.path = new cleverapps.Particles(bundles.particle.jsons.particles_drag_json, bundles.particle.frames.particle_texture);
        path.setScale(1 / Map2dScroller.currentScroller.getScrollZoom());
        path.setPosition(this.width / 2, this.height / 2);
        this.addChild(path);
        path.setLocalZOrder(-1);
    }

    if (this.sprite instanceof cleverapps.Spine && this.sprite.hasAnimation("fly")) {
        this.sprite.setCompleteListener();
        this.sprite.setAnimation(0, "fly", true);
        this.sprite.setSafeToRemove();
    }

    this.dragStartTime = Date.now();
    var dragOffsetY = cleverapps.styles.UnitView.dragOffsetY;
    this.runAction(new cc.MoveBy(0.1, 0, dragOffsetY));

    cleverapps.audio.playSound(bundles.merge.urls.start_drag_effect);

    return true;
};

UnitView.prototype.handleFollowPointer = function (touch) {
    if (!this.startDragDelta) {
        cleverapps.throwAsync("handleFollowPointer without delta - " + JSON.stringify({
            code: this.unit.code,
            stage: this.unit.stage,
            deltaCreate: this.debugDeltaCreate,
            deltaDelete: this.debugDeltaDelete
        }));
    }

    var position = this.parent.convertTouchToNodeSpace(touch);

    if (this.startDragDelta) {
        position.x += this.startDragDelta.x;
        position.y += this.startDragDelta.y;
    }

    var dragOffsetY = cleverapps.styles.UnitView.dragOffsetY;
    if (this.dragStartTime) {
        dragOffsetY *= Math.min(1, (Date.now() - this.dragStartTime) / 100);
    }
    position.y += dragOffsetY;

    this.setPositionRound(position);
};

UnitView.prototype.handleDragMove = function () {
    var mapView = Map2d.currentMap.getMapView();
    var move = mapView.getCellByCoordinates(this, true);
    this.unit.handleDragMove(move.x, move.y);
};

UnitView.prototype.removePath = function () {
    if (this.path) {
        this.path.stop();
        this.path = undefined;
    }
};

UnitView.prototype.handleDragEnd = function (cancelled) {
    this.debugDeltaDelete = {
        when: new Date(),
        stack: new Error().stack
    };

    this.markNormal();

    this.removePath();

    if (this.sprite instanceof cleverapps.Spine && this.sprite.hasAnimation("fly")) {
        this.sprite.setAnimation(0, "fall", false);
        this.sprite.setCompleteListener(function () {
            UnitView.applyDefaultAnimation(this.sprite, this.unit);

            this.unit.showAdditionalViews();
        }.bind(this));
        this.sprite.setSafeToRemove();
    } else {
        this.unit.showAdditionalViews();
    }
    var unitData = this.unit.getData();
    if (unitData.tank || unitData.customer) {
        if (cleverapps.config.name === "wondermerge") {
            cleverapps.audio.playSound(bundles.merge.urls.spawn_landing_effect);
        }
        var skin = cleverapps.skins.getSlot("skinName");
        var bundle = skin ? bundles["hero_sound_" + skin] : bundles.hero_sound;
        var fallSfx = bundle && bundle.urls[this.unit.code + "_fall"];
        if (fallSfx) {
            this.sprite.runAction(new cc.Sequence(
                new cc.DelayTime(0.23),
                new cc.PlaySound(fallSfx, { throttle: 1500 })
            ));
        }
    }

    skin = cleverapps.skins.getSlot("unitMoveAnimation");
    if (skin) {
        var animation = new cleverapps.Spine(skin.json);
        animation.setAnimation(0, "animation", false);
        animation.setCompleteListenerRemove();
        animation.setPositionRound(this.width / 2, this.sprite.y + (1 - this.sprite.anchorY) * this.sprite.height * this.sprite.scaleY);
        animation.setLocalZOrder(skin.zOrder);
        animation.setSafeToRemove();
        this.addChild(animation);

        cleverapps.audio.playSound(skin.sound);
    }

    delete this.startDragDelta;
    delete this.dragStartTime;

    this.unit.handleDragEnd(cancelled);
};

UnitView.prototype.markNormal = function () {
    if (this.sprite) {
        this.sprite.setColor(this.sprite.normalColor || cleverapps.styles.COLORS.WHITE);
    }
};

UnitView.prototype.markDragging = function () {
    if (this.sprite) {
        this.sprite.setColor(cleverapps.styles.COLORS.DRAGGING_UNIT_COLOR);
    }
};

UnitView.prototype.markDragTarget = function () {
    if (this.sprite) {
        this.sprite.setColor(cleverapps.styles.COLORS.DRAGG_TARGET_UNIT_COLOR);
    }
};

UnitView.prototype.markError = function () {
    if (this.sprite) {
        this.sprite.setColor(cleverapps.styles.COLORS.COLOR_RED);
    }
};

UnitView.prototype.move = function (options) {
    options = options || {};
    var mapView = Map2d.currentMap.getMapView();
    var type, view;

    for (type in this.additionalViews) {
        view = this.additionalViews[type];
        view.replaceParent(this);
        view.setPositionRound(view.basePosition);
    }

    var pos = mapView.alignInGrid(this.unit.x, this.unit.y);
    if (this.unit.isMultiCell()) {
        pos = mapView.alignPositionInGrid(
            this.unit.x,
            this.unit.y,
            MultiCellView.calcCenterPosition(this.unit.findComponent(MultiCell).shape, this)
        );
    }
    this.setScale(1);
    this.setVisible(true);
    this.stopAllActions();
    this.removePath();

    this.replaceParentSamePlace(mapView.animations);

    var actions = [];
    actions.push(options.isNewPlace ? StandartAnimations.fallDown(this, bundles.merge_animations.jsons.dragend_json)
        : new cc.MoveTo(0.25, pos).easing(cc.easeOut(2)));

    if (Map2d.currentMap.isFlat) {
        var normalizeVector = cc.pNormalize(cc.pSub(pos, this.getPosition()));
        var length = cleverapps.styles.UnitView.pushingLength;

        actions = [
            new cc.MoveTo(0.15, cc.pAdd(pos, cc.pMult(normalizeVector, length))).easing(cc.easeCubicActionOut()),
            new cc.MoveTo(0.1, pos).easing(cc.easeInOut(2))
        ];

        if (options.isPushed) {
            var baseZOrder = this.getLocalZOrder();
            this.setLocalZOrder(-1);

            actions.push(new cc.CallFunc(this.setLocalZOrder.bind(this, baseZOrder)));
        } else {
            actions.push(new cc.CallFunc(function () {
                Map2d.currentMap.selectUnit(this.unit);
            }.bind(this)));
        }
    }

    if ((options.isPushed || !options.isNewPlace) && bundles.game.urls.swap_effect) {
        actions.push(new cc.PlaySound(bundles.game.urls.swap_effect));
    } else {
        actions.push(new cc.PlaySound(bundles.merge.urls.spawn_landing_effect));
    }

    this.runAction(new cc.Sequence(actions).setFinalize(function () {
        mapView.addTile(Map2d.LAYER_UNITS, this.unit.x, this.unit.y, this);

        for (type in this.additionalViews) {
            view = this.additionalViews[type];
            mapView.upAdditionalViewAboveClouds(this, view);
        }
    }.bind(this)));
};

UnitView.GetUnitBundle = function (unit) {
    var data = Families[unit.code].units[unit.stage];
    if (data.lazy) {
        return bundles["unit_" + unit.code + "_" + (unit.stage + 1)];
    }
    return bundles["unit_" + unit.code];
};

UnitView.UnitIconFrame = function (unit) {
    var unitKey = "unit_" + unit.code + "_" + (unit.stage + 1);
    var bundle = UnitView.GetUnitBundle(unit);
    var res = bundle.frames[unitKey] || bundle.frames[unitKey + "_frame"];
    return res && res.resolve();
};

UnitView.UnitIconJson = function (unit) {
    var unitKey = "unit_" + unit.code + "_" + (unit.stage + 1);
    var bundle = UnitView.GetUnitBundle(unit);
    var res = bundle.jsons[unitKey];
    return res && res.resolve();
};

UnitView.getUnitAnchor = function (unit, options) {
    options = options || {};

    var anchors = { x: 0.5, y: 0.5 };

    var res = UnitView.UnitIconJson(unit) || UnitView.UnitIconFrame(unit);
    var data = res && res.getAnchors();
    if (!data) {
        return anchors;
    }

    if (data.x !== undefined) {
        anchors.x = data.x;
    }

    if (data.y !== undefined) {
        anchors.y = data.y;
    }

    if (options.useLibraryAnchors) {
        anchors.y = 0.5;

        if (anchors.library && anchors.library.x !== undefined) {
            anchors.x = anchors.library.x;
        }

        if (anchors.library && anchors.library.y !== undefined) {
            anchors.y = anchors.library.y;
        }
    }

    return anchors;
};

UnitView.getUnitAnimations = function (unit) {
    return cleverapps.styles.UnitAnimations[unit.code] && cleverapps.styles.UnitAnimations[unit.code]["stage" + (unit.stage + 1)];
};

UnitView.getPreview = function (unit, res, options) {
    var preview;

    var data = Families[unit.code].units[unit.stage];
    if (data.lazy) {
        preview = new cleverapps.Spine(bundles.lazypreview.jsons.preview);
        preview.setAnchorPoint(options.useLibraryAnchors ? 0.5 : cleverapps.styles.UnitView.preview.anchorX, options.useLibraryAnchors ? 0.5 : cleverapps.styles.UnitView.preview.anchorY);
        preview.setAnimation(0, options.preferStatic ? "static" : "animation", true);
    } else {
        preview = UnitView.getUnitImage({ code: "unknown", stage: 0 }, {
            preferStatic: options.preferStatic,
            useLibraryAnchors: options.useLibraryAnchors,
            alignAnchorX: options.alignAnchorX,
            alignAnchorY: options.alignAnchorY
        });
    }

    var anchors = UnitView.getUnitAnchor(unit, options);
    var dimensions = res.getDimensions() || preview.getContentSize();

    preview.setAnchorPoint(
        preview.anchorX + dimensions.width / preview.width * (0.5 - anchors.x),
        preview.anchorY + dimensions.height / preview.height * (0.5 - anchors.y)
    );

    return preview;
};

UnitView.getUnitImage = function (unit, options) {
    options = options || {};
    var code = unit.code;

    if (unit.unknown) {
        return new cc.Sprite(bundles.merge.frames.unit_unknown);
    }

    var json = UnitView.UnitIconJson(unit);
    var frame = UnitView.UnitIconFrame(unit);

    if (!json && !frame) {
        json = SwitchableView.UnitIconJson(unit, 0);
        if (!json) {
            throw "Not found frame unit_" + code + "_" + (unit.stage + 1);
        }
    }

    var image;
    if (frame && (options.preferStatic || !json)) {
        image = frame.isLazy() ? new LazyAsset(frame, { preview: UnitView.getPreview(unit, frame, options), keepLazy: options.keepLazy }) : new cc.Sprite(frame);
    }

    if (json) {
        image = json.isLazy() ? new LazyAsset(json, { preview: UnitView.getPreview(unit, json, options), keepLazy: options.keepLazy }) : new cleverapps.Spine(json);
        UnitView.applyDefaultAnimation(image, unit, options.defaultAnimation);
        if (options.preferStatic) {
            image.clearTrack(0);
        }
    }

    var useAnchorBone = image.getAnchorBone && image.getAnchorBone();

    if (!useAnchorBone) {
        var anchors = UnitView.getUnitAnchor(unit, options);
        anchors.x = options.alignAnchorX || options.useLibraryAnchors ? anchors.x : 0.5;
        anchors.y = options.alignAnchorY || options.useLibraryAnchors ? anchors.y : 0.5;
        image.setAnchorPoint(anchors.x, anchors.y);
    }

    if (cleverapps.config.debugMode) {
        image.debugUnitImage = true;
    }

    return image;
};

UnitView.applyDefaultAnimation = function (animation, unit, defaultAnimation) {
    var applyDefaultAnimation = function () {
        var animations = UnitView.getUnitAnimations(unit);
        if (defaultAnimation && animation.hasAnimation(defaultAnimation)) {
            animation.setAnimation(0, defaultAnimation, true);
        } else if (animations) {
            animation.setIdleSet(animations);
        } else {
            animation.setAnimation(0, animation.hasAnimation("idle") ? "idle" : "animation", true);
        }

        animation.setSafeToRemove();
        if (unit.animateOutOfSync && unit.animateOutOfSync() && animation.getTimeScale() === 1) {
            animation.setTimeScale(0.9 + 0.3 * Math.random());
        }
    };

    if (animation instanceof LazyAsset) {
        animation.whenAssetLoaded(applyDefaultAnimation);
    } else {
        applyDefaultAnimation();
    }
};

UnitView.prototype.playDestruction = function (silent, originUnit) {
    this.stopAllActions();
    this.removePath();

    if (originUnit) {
        this.explodeByProjectileAnimation(originUnit);
    } else if (cleverapps.skins.getSlot("isometricExplode") || cleverapps.config.name === "hustlemerge") {
        this.isometricExplodeAnimation(silent);
    } else {
        this.explodeAnimation(silent);
    }
};

UnitView.prototype.isometricExplodeAnimation = function (silent) {
    var movingNode = cleverapps.scenes.getMovingNode(this);

    this.replaceParentSamePlace(movingNode);

    var animation = new cleverapps.Spine(bundles.merge_animations.jsons.isometric_explode_json || bundles.merge_animations.jsons.destruction_json);
    animation.setAnimation(0, "animation", false);
    animation.setCompleteListenerRemove(this.removeFromParent.bind(this));
    animation.setSafeToRemove();
    animation.setPosition(this.x, this.y);
    movingNode.addChild(animation, -1);

    if (this.unit.findComponent(Grounded)) {
        this.unit.findComponent(Grounded).remove();
    }

    cleverapps.audio.playSound(bundles.merge.urls.unit_desturction_effect);

    if (!silent) {
        this.sprite.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.3).easing(cc.easeIn(1)),
            new cc.Spawn(
                new cc.ScaleTo(0.1, 0.5).easing(cc.easeOut(1)),
                new cc.FadeTo(0.1, 180)
            ),
            new cc.Hide(),
            new cc.CallFunc(this.removeFromParent.bind(this))
        ));
    }
};

UnitView.prototype.explodeAnimation = function (silent) {
    var animation = new cleverapps.Spine(bundles.merge_animations.jsons.destruction_json);
    animation.setPositionRound(this.width / 2, this.height / 2);
    animation.setAnimation(0, "animation", false);
    animation.setCompleteListenerRemove(this.removeFromParent.bind(this));
    animation.setSafeToRemove();
    this.addChild(animation);

    var movingNode = cleverapps.scenes.getMovingNode(this);

    this.replaceParentSamePlace(movingNode);
    animation.replaceParentSamePlace(movingNode);

    if (this.unit.findComponent(Grounded)) {
        this.unit.findComponent(Grounded).remove();
    }

    cleverapps.audio.playSound(bundles.merge.urls.unit_desturction_effect);

    if (!silent) {
        this.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.2, 1.3, 1.3).easing(cc.easeIn(1)),
                new cc.ScaleTo(0.2, 0.5, 0.5).easing(cc.easeOut(1)),
                new cc.RemoveSelf()
            )
        );
    }
};

UnitView.prototype.explodeByProjectileAnimation = function (originUnit) {
    var originView = originUnit.onGetView();

    var animation = new cleverapps.Spine(bundles.axemerge.jsons.merge_projectile);
    animation.setAnimation(0, "idle", true);
    animation.setPositionRound(cleverapps.styles.UnitView.projectile);
    animation.setSafeToRemove();
    originView.addChild(animation);

    var parent = cleverapps.scenes.getMovingNode(this);
    animation.replaceParentSamePlace(parent);
    var duration = 0.4;

    animation.runAction(new cc.Sequence(
        new cc.MoveTo(duration, parent.convertToNodeSpace(this.convertToWorldSpace(cc.p(this.width / 2, this.height / 2)))).easing(cc.easeInOut(1.4)),
        new cc.RemoveSelf()
    ));

    this.runAction(new cc.Sequence(
        new cc.DelayTime(duration - 0.02),
        new cc.CallFunc(this.explodeAnimation.bind(this, false))
    ));
};

UnitView.prototype.openAnimation = function () {
    this.stopAllActions();
    this.removePath();

    var animation = new cleverapps.Spine(bundles.merge_animations.jsons.appearance_json);
    animation.setPositionRound(this.width / 2, this.height / 2);
    animation.setAnimation(0, "animation", false);
    animation.setCompleteListenerRemove(this.removeFromParent.bind(this));
    animation.setSafeToRemove();
    this.addChild(animation);

    animation.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this));

    this.runAction(
        new cc.Sequence(
            new cc.ScaleTo(0.2, 1.3, 1.3).easing(cc.easeIn(1)),
            new cc.ScaleTo(0.2, 0.5, 0.5).easing(cc.easeOut(1)),
            new cc.RemoveSelf()
        )
    );
};

UnitView.prototype.playAddAnimation = function () {
    var map2dView = Map2d.currentMap.getMapView();
    var shine = new cleverapps.Spine(bundles.buildable_animation.jsons.buildable_above_json);
    shine.setLocalZOrder(1);
    shine.setAnimation(0, "animation_bonus", false);
    shine.setCompleteListenerRemove();
    shine.setSafeToRemove();
    map2dView.alignViewInGrid(this.unit.x, this.unit.y, shine);
    map2dView.animations.addChild(shine);

    this.setVisible(false);
    this.setScale(0.3);
    this.addAnimationAction = this.runAction(new cc.Sequence(
        new cc.DelayTime(0.3),
        new cc.PlaySound(bundles.thirdelement.urls.thirdelement_appearance_effect),
        new cc.Show(),
        new cc.ScaleTo(0.4, 1.3),
        new cc.ScaleTo(0.2, 1)
    ));
};

UnitView.prototype.updateCustomerMark = function () {
    if (cleverapps.config.demoMode || cleverapps.gameModes.hideGuideAndProgress) {
        return;
    }

    if (this.unit.customerMark) {
        var styles = cleverapps.styles.UnitView;
        var markBg = new cc.Sprite(bundles.merge.frames.customer_check_mark_bg);
        markBg.setAnchorPoint(0, 0);
        markBg.setPositionRound(styles.mark);

        var mark = new cc.Sprite(bundles.merge.frames.customer_check_mark_flat);
        markBg.addChild(mark);
        mark.setPositionRound(markBg.width / 2, markBg.height / 2);

        this.animateAppearAdditionalView("customerMark", markBg);
    } else {
        this.removeAdditionalView("customerMark");
    }
};

UnitView.prototype.createPrizeView = function () {
    this.removeAdditionalView("prize");

    var prizes = this.unit.prizes;
    if (prizes === undefined || cleverapps.config.demoMode) {
        return;
    }

    var prizeView = new PrizeView(prizes, {
        warning: this.unit.prizeWarning,
        onClick: this.handleClick.bind(this),
        unit: this.unit
    });
    prizeView.setPositionRound(this.width / 2, this.height);
    this.createAdditionalView("prize", prizeView);
};

UnitView.prototype.animatePrizeView = function () {
    var prizeView = this.getAdditionalView("prize");
    if (prizeView && !this.unit.additionalViewsHidden) {
        prizeView.animateAppear();

        if (!cleverapps.gameModes.silentInfoView) {
            cleverapps.audio.playSound(bundles.merge.urls.prize_view_created_effect, { throttle: 1000 });
        }
    }
};

UnitView.prototype.createAdditionalView = function (type, view) {
    this.removeAdditionalView(type, true);

    var additionalView = new UnitAdditionalView(this, view, type);
    Map2d.currentMap.getMapView().upAdditionalViewAboveClouds(this, additionalView);
    this.additionalViews[type] = additionalView;

    if (this.unit.additionalViewsHidden) {
        additionalView.hide();
    }

    return additionalView;
};

UnitView.prototype.animateAppearAdditionalView = function (type, view) {
    var onHideCallback = function () {
        var additionalView = this.createAdditionalView(type, view);
        if (additionalView && !this.unit.additionalViewsHidden) {
            additionalView.animateAppear();
        }
    }.bind(this);
    var oldView = this.additionalViews[type];
    if (oldView) {
        oldView.animateHide(onHideCallback);
    } else {
        onHideCallback();
    }
};

UnitView.prototype.getAdditionalView = function (type) {
    return this.additionalViews[type];
};

UnitView.prototype.listAdditionalViews = function () {
    return Object.values(this.additionalViews).filter(Boolean);
};

UnitView.prototype.removeAdditionalView = function (type, silent) {
    var view = this.additionalViews[type];
    delete this.additionalViews[type];

    if (view) {
        view.remove(silent);
    }

    return view;
};

UnitView.prototype.setImageOpacity = function (opacity, temp) {
    if (!temp) {
        this.baseOpacity = opacity;
    }

    this.sprite.setOpacity(opacity);
};

UnitView.prototype.restoreOpacity = function () {
    this.sprite.setOpacity(this.baseOpacity !== undefined ? this.baseOpacity : 255);
};

UnitView.prototype.onDeletePressed = function () {
    if (!confirm("Delete unit \"" + this.unit.code + "'?")) {
        return;
    }

    this.unit.remove();
    Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
};

UnitView.prototype.getAnimation = function () {
    if (this.sprite instanceof cleverapps.Spine || (this.sprite instanceof LazyAsset && this.sprite.isSpine())) {
        return this.sprite;
    }
};

UnitView.prototype.hideSprite = function () {
    this.sprite.setVisible(false);

    if (this.sprite instanceof cleverapps.Spine) {
        this.sprite.setStartVisibleListener(function () {
            this.sprite.setVisible(false);
        }.bind(this));
    }
};

UnitView.prototype.showSprite = function () {
    this.sprite.setVisible(true);

    if (this.sprite instanceof cleverapps.Spine) {
        this.sprite.setStartVisibleListener(undefined);
    }
};

UnitView.prototype.animateAppearPoints = function () {
    this.restoreState();
    if (this.pointAnimation) {
        var styles = cleverapps.styles.UnitView.pointsAnimation;
        this.pointAnimation.setPositionRound(this.pointAnimation.x, this.pointAnimation.y - styles.offsetY);
        this.pointAnimation.setScale(0.1);
        this.pointAnimation.runAction(new cc.ScaleTo(1, 1));
        this.pointAnimation.runAction(new cc.MoveBy(1, 0, styles.offsetY));
    }
};

UnitView.prototype.createPoints = function () {
    if (!this.unit.points) {
        return;
    }
    var styles = cleverapps.styles.UnitView.pointsAnimation;

    if (this.pointAnimation) {
        return;
    }

    var mission = cleverapps.missionManager.findByType(Mission.TYPE_SALEPASS);
    var isPurchaseBuildPass = mission && MissionManager.hasProperParent(mission.type);
    var pointAnimation = this.pointAnimation = new cleverapps.Spine(isPurchaseBuildPass ? bundles.merge.jsons.salepass_point : bundles.merge.jsons.buildpass_point);
    pointAnimation.setAnchorPoint(0.5, 0.5);
    pointAnimation.setPositionRound(styles);
    pointAnimation.setAnimation(0, isPurchaseBuildPass ? PassMissionLogic.classifyAmount(this.unit.points) + "_idle" : "idle", true);
    pointAnimation.setSafeToRemove();
    this.addChild(pointAnimation);
    cleverapps.UI.onClick(pointAnimation, this.unit.claimPoints.bind(this.unit));

    if (cleverapps.gameModes.hideUnitPointAnimation) {
        pointAnimation.setVisible(false);
    }
};

UnitView.prototype.hidePoints = function () {
    var styles = cleverapps.styles.UnitView.pointsAnimation;

    if (this.pointAnimation) {
        this.pointAnimation.stopAllActions();
        this.pointAnimation.runAction(new cc.ScaleTo(0.3, 0.1));
        this.pointAnimation.runAction(new cc.Sequence(
            new cc.MoveBy(0.3, 0, -styles.offsetY),
            new cc.RemoveSelf()
        ));
        delete this.pointAnimation;
    }
};

UnitView.prototype.claimPoints = function (callback) {
    if (!this.pointAnimation) {
        callback();
        return;
    }

    var pointAnimation = this.pointAnimation;
    this.pointAnimation = undefined;

    pointAnimation.stopAllActions();
    pointAnimation.replaceParentSamePlace(cleverapps.scenes.getRunningScene(), {
        keepScale: true
    });

    var passMission = cleverapps.missionManager.findLocalPass();
    if (!passMission) {
        this.hidePoints();
        return;
    }

    pointAnimation.runAction(new cc.Sequence(
        StandartAnimations.animateCollect(pointAnimation, "mission" + passMission.type, {
            adjustToTarget: true,
            collectEffect: true,
            sound: bundles.menubar.urls.exp_fly_finish_effect
        }),
        new cc.CallFunc(callback),
        new cc.RemoveSelf()
    ));
};

UnitView.prototype.getDnDAccepted = function (targetUnit) {
    var mapView = Map2d.currentMap.getMapView();

    this.replaceParentSamePlace(mapView.animations, {
        keepScale: true
    });

    var startPosition = this.getPosition();
    var finishPosition = mapView.getUnitCenterPos(targetUnit.x, targetUnit.y);
    var distance = cc.pDistance(startPosition, finishPosition);

    this.runAction(new cc.Sequence(
        new cc.JumpTo((0.004 * distance + 0.1) / (0.008 * distance + 1), finishPosition.x, finishPosition.y, 0.3 * distance, 1),
        new cc.PlaySound(bundles.fruit.urls.fruit_fly_finish_effect),
        new cc.RemoveSelf()
    ));
};

UnitView.prototype.animateFlinch = function () {
    this.sprite.runAction(new cc.Sequence(
        new cc.ScaleTo(0.1, 1.15).easing(cc.easeIn(1.2)),
        new cc.ScaleTo(0.1, 1).easing(cc.easeOut(1.2))
    ));
};

UnitView.prototype.collectIngredients = function (parentUnit, collected, callback) {
    var target = cleverapps.aims.getTarget(["warehouse"]);

    var movingNode = Map2d.currentMap.getMapView().animations;

    var startPosition = Map2d.currentMap.getMapView().alignInGrid(parentUnit.x, parentUnit.y);
    var multiCell = parentUnit.findComponent && parentUnit.findComponent(MultiCell);
    if (multiCell) {
        var centerDelta = multiCell.getCenter();
        startPosition.x += centerDelta.x;
        startPosition.y += centerDelta.y;
    }

    var groups = {};

    collected.forEach(function (prize) {
        if (!groups[prize.ingredient]) {
            groups[prize.ingredient] = 0;
        }

        groups[prize.ingredient] += prize.amount;
    });

    var actions = [];

    var delay = 0;

    Object.keys(groups).forEach(function (code, groupIndex, total) {
        var delta = groups[code];

        var flyingAmount;

        if (delta >= 100) {
            flyingAmount = 8;
        } else if (delta >= 50) {
            flyingAmount = 5;
        } else if (delta >= 3) {
            flyingAmount = 3;
        } else {
            flyingAmount = delta;
        }

        delay -= delay / 2;

        cleverapps.arrayFill(flyingAmount).forEach(function (value, index) {
            var item = Merge.currentMerge.harvested.getIngredientSmallIcon(code);
            item.setPositionRound(startPosition);
            movingNode.addChild(item);

            item.setLocalZOrder(1 / (1 + index));
            item.setVisible(false);

            delay += Math.random() * 0.1;

            var action = [
                new cc.DelayTime(delay),
                new cc.Show(),
                StandartAnimations.jumpCollect(item, target, {
                    collectMovingNode: cleverapps.windows.isActive() && movingNode,
                    jumpOffset: index / flyingAmount,
                    longJump: cleverapps.skins.getSlot("useLongJumpAnimation"),
                    collectDelay: 0.1 * total.length + 0.1 * groupIndex,
                    collectSound: bundles.fruit.urls.fruit_fly_finish_effect
                }),
                new cc.RemoveSelf()
            ];

            delay += 0.1;

            if (index === flyingAmount - 1) {
                action.push(new cc.CallFunc(function () {
                    cleverapps.aims.showDelta(delta, target, {
                        icon: Merge.currentMerge.harvested.getIngredientSmallIcon(code)
                    });
                }));
            }

            actions.push(new cc.TargetedAction(item, new cc.Sequence(action)));
        });
    });

    if (actions.length === 0) {
        return;
    }

    if (actions.length === 1) {
        movingNode.runAction(actions[0]);
    } else {
        movingNode.runAction(new cc.Spawn(actions));
    }

    movingNode.runAction(new cc.Sequence(
        new cc.DelayTime(delay + (cleverapps.skins.getSlot("useLongJumpAnimation") ? 0.8 : 0.4)),
        new cc.CallFunc(callback)
    ));
};

UnitView.prototype.makePartsVisible = function (visible) {
    if (this.partsViews) {
        this.partsViews.forEach(function (part) {
            part.setVisible(visible);
        });
    }
};

UnitView.prototype.markLast = function () {
    var bundle = bundles.merge.jsons.mark_last_json;
    if (!bundle) {
        return;
    }

    if (!this.markLastAnimation) {
        this.markLastAnimation = new cleverapps.Spine(bundle);
    }

    var mark = this.markLastAnimation;

    mark.setAnimation(0, "idle", true);
    mark.setPositionRound(cleverapps.styles.UnitView.markLast);

    this.addChild(mark);
};

cleverapps.styles.UnitAnimations = {};

cleverapps.styles.UnitView = {
    merge: 16,
    pushingLength: 15,
    preview: { anchorX: 0.47, anchorY: 0.28 },
    minimalSize: {
        width: 108,
        height: 80
    },

    enableSpawnPath: true,
    spawnHeight: 240,
    mergeSpawnHeight: 70,
    dragOffsetY: 30,

    pointsAnimation: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 60 },
        offsetY: 50
    },

    mergeAnimation: {
        x: 0,
        y: 0,
        delay: 0.2
    },

    mergeAnimationBelow: {
        x: 0,
        y: 0,
        delay: 0.2
    },

    didMerge: {
        delay: 0.6
    },

    mark: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -50 }
    },

    projectile: {
        x: { align: "center" },
        y: { align: "center", dy: -40 }
    },

    dragend: {
        x: { align: "center" },
        y: { align: "center" }
    },

    markLast: {
        x: { align: "center", dx: -35 },
        y: { align: "bottom", dy: -5 }
    },

    delta: {
        moveRate: 0.3
    }
};
