/**
 * Created by mac on 12/23/20
 */

var ResourceCollectible = function (unit) {
    UnitComponent.call(this, unit);

    if (typeof unit.getData().resourceCollectible === "object") {
        this.targetType = unit.getData().resourceCollectible.targetType;
    }
};

ResourceCollectible.prototype = Object.create(UnitComponent.prototype);
ResourceCollectible.prototype.constructor = ResourceCollectible;

ResourceCollectible.prototype.onCollection = function (index, target, duration) {
    if (this.view) {
        this.view.collectionAnimation(index, target, duration);
    } 
};

ResourceCollectible.prototype.handleClick = function () {
    var target = this.getResourceTarget();
    if (!target) {
        return false;
    }

    this.collect(target);
    return true;
};

ResourceCollectible.prototype.getResourceTarget = function () {
    var units = Map2d.currentMap.listAvailableUnits(this.targetType ? { type: this.targetType } : { code: this.unit.code });

    for (var i = 0; i < units.length; i++) {
        var upgradable = units[i].findComponent(Upgradable);
        if (upgradable && upgradable.canCollect(this.unit)) {
            return upgradable.unit;
        }
        if (upgradable && upgradable.canCollect(this.unit, { skipBuild: true })) {
            cleverapps.centerHint.createTextHint("cantCollectBuilding", { target: Messages.get("Units." + upgradable.unit.code + ".stage" + (upgradable.unit.stage + 1) + ".name") });
            return;
        }
    }
};

ResourceCollectible.prototype.collect = function (target) {
    this.unit.claimPoints();
    if (this.unit.findComponent(Pulsing)) {
        this.unit.findComponent(Pulsing).setRemoved(true);
    }
    var speed = 30; // cell/second
    var distance = Math.sqrt(Math.pow(target.x - this.unit.x, 2) + Math.pow(target.y - this.unit.y, 2));
    var duration = Math.max(0.5, distance / speed);

    this.onCollection(0, target, duration);

    cleverapps.audio.playSound(bundles.main.urls.click_effect);

    if (Merge.currentMerge.advice) {
        Merge.currentMerge.advice.boo();
    }

    if (target.isUpgradable()) {
        target.findComponent(Upgradable).incProgress(duration * 1000);
    }

    this.unit.remove(true);
};