/**
 * Created by iamso on 27.08.2021
 */

var BattlePassPoints = function (unit, options) {
    UnitComponent.call(this, unit);

    this.load(options || {});
};

BattlePassPoints.prototype = Object.create(UnitComponent.prototype);
BattlePassPoints.prototype.constructor = BattlePassPoints;

BattlePassPoints.prototype.collect = function () {
    if (this.unit.findComponent(Pulsing)) {
        this.unit.findComponent(Pulsing).setRemoved(true);
    }

    if (Merge.currentMerge.advice) {
        Merge.currentMerge.advice.boo();
    }

    var mission = cleverapps.missionManager.findLocalPass();
    if (!mission || !mission.isRunning()) {
        this.unit.onDestruction();
        this.unit.remove(true);
        return;
    }

    var reward = new Reward("mission", { amount: this.pointsValue, missionType: mission.type });
    reward.receiveReward();

    var source = this.unit.onGetView();
    if (!source) {
        this.unit.onDestruction();
        this.unit.remove(true);
        return;
    }

    this.unit.remove(true);

    reward.collectRewardsAnimation(source, {
        path: true,
        adjustToTarget: true,
        flyingAnimation: Reward.COLLECT_ANIMATION
    });
};

BattlePassPoints.prototype.handleClick = function () {
    this.collect();
    return true;
};

BattlePassPoints.prototype.load = function (stored) {
    if (stored.pointsValue) {
        this.pointsValue = stored.pointsValue;
    } else {
        var pointsData = this.unit.getData().pointsReward;
        this.pointsValue = cleverapps.Random.random(pointsData.min, pointsData.max);
    }
};

BattlePassPoints.prototype.save = function (data) {
    data.pointsValue = this.pointsValue;
};